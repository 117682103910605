import { CATALOG_DEFAULT_VIEW_MODE } from '@bluheadless/ui-logic/src/constants'
import { cx } from '@emotion/css'
import { Root, SwitchStyled } from '@/components/ui/molecules/model-view/model-view.styled'
import { useIntl } from 'react-intl'
import { useState } from 'react'

const ModelView = ({ variant, onClick, className }) => {
	const [mode, setMode] = useState(variant ?? CATALOG_DEFAULT_VIEW_MODE)

	const onClickHandler = (mode) => {
		setMode(mode)
		onClick && onClick(mode)
	}

	const { formatMessage } = useIntl()

	const toggleMode = () => {
		const newMode = mode === CATALOG_DEFAULT_VIEW_MODE ? 'alternate' : CATALOG_DEFAULT_VIEW_MODE
		onClickHandler(newMode)
	}

	return (
		<Root className={cx('ModelView-root', className)}>
			<SwitchStyled
				onChange={toggleMode}
				checked={mode != CATALOG_DEFAULT_VIEW_MODE}
				label={formatMessage({ id: 'model_view_label' })}
			/>
		</Root>
	)
}

export default ModelView
