import { styled } from '@mui/system'

const shouldForwardProp = (prop) => !['columnsGap', 'columns', 'rowsGap', 'rows', 'items', 'breakpoint'].includes(prop)

const GridRoot = styled('div', { shouldForwardProp })`
	display: grid;
	// use minmax(0px, 1fr) to avoid overflow-x issue with Swiper
	// rif. https://github.com/nolimits4web/swiper/issues/3599#issuecomment-1290897438
	grid-template-columns: repeat(${({ columns }) => columns}, minmax(0px, 1fr));
	column-gap: ${({ columnsGap }) => columnsGap || 0}px;
	row-gap: ${({ rowsGap }) => rowsGap || 0}px;
	border-top: 1px solid ${({ theme }) => theme.palette.primary.main};
	border-left: 1px solid ${({ theme }) => theme.palette.primary.main};
`

const GridItem = styled('div', { shouldForwardProp })`
	grid-column-end: span ${({ columns }) => columns || 1};
	grid-row-end: span ${({ rows }) => rows || 1};
	border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
	position: relative;
	overflow: hidden;
	&::after {
		content: '';
		display: block;
		clear: both;
		width: 1px;
		height: 100%;
		background: ${({ theme }) => theme.palette.primary.main};
		position: absolute;
		right: 0;
		z-index: 2;
		top: 0;
	}
`

export { GridRoot, GridItem }
