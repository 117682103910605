import { styled } from '@mui/system'
import BHSwitch from '@bluheadless/ui/src/molecules/switch'

export const Root = styled('div')`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing(2)};
	flex-basis: 50%;
	justify-content: flex-end;
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-basis: unset;
		justify-content: unset;

		.MuiFormControlLabel-label {
			margin-right: ${({ theme }) => theme.spacing(4)};
		}
	}

	.MuiFormControlLabel-root {
		margin-right: 0;
	}
`

export const SwitchStyled = styled(BHSwitch)`
	height: 17px;
	padding: 0;
	width: 32px;

	.MuiSwitch-thumb {
		background-color: ${({ theme }) => theme.palette.secondary.main};
	}
	.MuiButtonBase-root {
		width: 13px;
		height: 8px;
		left: 2px;
		input {
			width: 32px;
			left: -17px;
			&.Mui-checked {
				left: -40px;
			}
		}
		.MuiSwitch-thumb {
			background-color: ${({ theme }) => theme.palette.secondary.main};
			width: 12px;
			height: 12px;
		}
		&.Mui-checked {
			transform: translateX(15px);
			& + .MuiSwitch-track {
				background-color: ${({ theme }) => theme.palette.primary.main};
			}
		}
	}

	& + span {
		text-transform: uppercase;
		font-size: ${({ theme }) => theme.typography.pxToRem(12)};
		font-weight: ${({ theme }) => theme.typography.fontWeightBold};
		letter-spacing: 1.2px;
		line-height: 2;
		cursor: pointer;
		color: ${({ theme }) => theme.palette.primary.main};
		${({ theme }) => theme.breakpoints.up('md')} {
			font-size: ${({ theme }) => theme.typography.pxToRem(10)};
		}
	}
`
