import { styled } from '@mui/system'
import Icon from '../../atoms/icon'
import { SwiperSlide } from 'swiper/react'

const shouldForwardProp = (prop) => !['action', 'aboveTheFold'].includes(prop)

const Container = styled('div', { shouldForwardProp })`
	position: relative;
	--swiper-navigation-size: 48px;
	height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
`

const NavButton = styled(Icon, { shouldForwardProp })`
	color: ${({ theme }) => theme.palette.common.white};
	font-size: ${({ theme }) => theme.typography.pxToRem(48)};
	${({ action }) => (action === 'prev' ? 'left: 16px;' : 'right: 16px;')}

	&.swiper-button-disabled {
		pointer-events: auto;
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		border-radius: 50%;
		color: ${({ theme }) => theme.palette.primary.main};
		padding: ${({ theme }) => theme.spacing(1)};
		fill: rgba(255, 255, 255, 0.3);
		color: ${({ theme }) => theme.palette.secondary.main};
		background: rgba(255, 255, 255, 0.3);
		font-size: ${({ theme }) => theme.typography.pxToRem(48)};
	}
`

const PaginationStyled = styled('div')`
	&.swiper-pagination-horizontal {
		bottom: 6px;
		width: auto;
		left: 50%;
		transform: translateX(-50%);
		${({ theme }) => theme.breakpoints.up('md')} {
			width: 100%;
			left: 0;
			transform: none;
		}
	}

	&.swiper-pagination-bullets {
		line-height: 0;
	}

	.swiper-pagination-bullet {
		background-color: transparent;
		height: auto;
		margin: ${({ theme }) => theme.spacing(0, 0.5)};
		opacity: 1;
		padding: ${({ theme }) => theme.spacing(0.5)};
		width: auto;
		&:before {
			background-color: ${({ theme }) => theme.palette.grey.light};
			border-radius: 50%;
			content: '';
			display: block;
			height: ${({ theme }) => theme.spacing(1)};
			width: ${({ theme }) => theme.spacing(1)};
		}
		&-active {
			cursor: default;
			&:before {
				background-color: ${({ theme }) => theme.palette.grey.main};
			}
		}
	}
`

const SwiperSlideStyled = styled(SwiperSlide, { shouldForwardProp })`
	display: ${({ aboveTheFold }) => (aboveTheFold ? 'flex' : undefined)};
`

export { Container, NavButton, PaginationStyled, SwiperSlideStyled }
