import { default as ModelViewBase } from '@/components/ui/molecules/model-view/model-view'
import styled from '@emotion/styled'

export const ModelView = styled(ModelViewBase)`
	flex-basis: unset;
	.MuiFormControlLabel-label {
		margin-right: ${({ theme }) => theme.spacing(2)};
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		justify-content: flex-end;
	}
`
