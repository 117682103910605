import { cx } from '@emotion/css'
import Grid from '@mui/material/Grid'
import { arrayOf, node, number, objectOf, oneOfType, shape, string } from 'prop-types'
import { ArtDirectionWrapper } from '../../particles/art-direction'
import { GridItem, GridRoot } from './advanced-grid.styled'
import ImageProvider from '@bluheadless/ui-logic/src/providers/image'
import { roundUp5 } from '@bluheadless/ui-logic/src/utils/math'

const ChildComponent = ({ items, ...props }) => {
	return (
		<GridRoot className={cx('AdvancedGrid-root')} {...props}>
			{items?.map(({ id, content, contentColumns, contentRows }) => {
				const columns = Math.min(Math.max(parseInt(contentColumns), 1), props.columns)
				const imageSizes = roundUp5((columns / props.columns) * 100)
				return (
					<GridItem className={cx('AdvancedGrid-item')} key={id} columns={columns} rows={contentRows}>
						<ImageProvider sizes={imageSizes}>{content}</ImageProvider>
					</GridItem>
				)
			})}
		</GridRoot>
	)
}

const AdvancedGrid = ({ responsiveProps, breakpoints, ...props }) => {
	return (
		<ArtDirectionWrapper
			responsiveProps={responsiveProps}
			breakpoints={breakpoints}
			ChildComponent={ChildComponent}
			{...props}
		/>
	)
}

AdvancedGrid.defaultProps = {
	...Grid.defaultProps,
	items: [],
	responsiveProps: {
		xs: {
			columns: 2,
		},
		md: {
			columns: 4,
		},
	},
}

AdvancedGrid.propTypes = {
	...Grid.propTypes,
	items: arrayOf(
		shape({
			id: oneOfType([number, string]).isRequired,
			content: node,
			contentColumns: number,
			contentRows: number,
		})
	).isRequired,
	responsiveProps: objectOf(
		oneOfType([
			string,
			shape({
				columns: number,
				columnsGap: number,
				rowsGap: number,
			}),
		])
	),
	breakpoints: objectOf(number),
}

export default AdvancedGrid
